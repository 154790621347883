import React, { useState } from 'react';
import { DashboardGraph, DashboardGraphProps } from '../graph';
import { FundSeriesTable, FundsSeriesTableProps } from '../table';
import { LoadingOverlay } from '../overlay';

export interface DashboardGraphTogglerProps {
  data: DashboardGraphProps['data'];
  subtitle: string;
  fundId: number;
  loading: boolean;
}

export function DashboardGraphToggler({
  data,
  subtitle,
  fundId,
  loading,
}: DashboardGraphTogglerProps) {
  const [activeTab, setActiveTab] = useState<'graph' | 'table'>('table');

  const seriesData: FundsSeriesTableProps = {
    url: `/api/v1/investors/fund-series/?fund_id=${fundId}`,
    columns: [
      { text: 'Nombre serie', sortLabel: 'name' },
      {
        text: 'Valor cuota',
        sortLabel: 'quota_price',
      },
      {
        text: 'Total cuotas',
        sortLabel: 'total_shares',
      },
      {
        text: 'Mis cuotas',
        sortLabel: 'number_of_quotes',
      },
      {
        text: 'Mi inversión',
        sortLabel: 'my_assets',
      },
      {
        text: 'Participación',
        sortLabel: 'percentage_of_shares',
      },
    ],
  };
  return (
    <div className="card position-relative overflow-hidden">
      <div className="card-body">
        <LoadingOverlay show={loading} />
        <div className="d-flex justify-content-between">
          <p className="h6 text-gray-800">
            {activeTab === 'graph' ? 'Estado de las promesas' : 'Mi inversión'}
          </p>
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn ${activeTab === 'table' ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setActiveTab('table')}
              disabled={loading}
            >
              Mi inversión
            </button>
            <button
              type="button"
              className={`btn ${activeTab === 'graph' ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setActiveTab('graph')}
              disabled={loading}
            >
              Promesas
            </button>
          </div>
        </div>
        <p className="text-gray-600">{subtitle}</p>
        {activeTab === 'graph'
          ? <DashboardGraph jsonObject={{ data }} />
          : (
            <FundSeriesTable
              jsonObject={{ columns: seriesData.columns, url: seriesData.url, hideQuotes: false }}
            />
          )}
      </div>
    </div>
  );
}
