import dayjs from 'dayjs';

type FormatToLocaleNumberStringParams = {
  value: string | number,
  currencyFormat?: 'clp' | 'uf',
  round?: boolean
};

/**
 * Transform the received value using Intl.NumberFormat to es-CL format.
 * If the round property is set to false, then a minimum of 4 decimals are set.
 * @param param0
 * @returns
 */
export const formatToLocaleNumberString = ({
  value,
  currencyFormat,
  round = false,
}: FormatToLocaleNumberStringParams) => {
  const valueToFormat = round ? Math.round(Number(value)) : value;
  const currencyOptions = currencyFormat === 'clp' ? { style: 'currency' as const, currency: 'CLP' } : {};
  const formatter = new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: round ? 0 : 4,
    ...currencyOptions,
  });
  const formattedNumber = formatter.format(typeof valueToFormat === 'string' ? Number(valueToFormat) : valueToFormat);
  return `${currencyFormat === 'uf' ? 'UF ' : ''}${formattedNumber}`;
};

/**
 * Format the received date string to DD-MM-YYYY format.
 * If the received string is not a valid date then the
 * original string is returned.
 * @param {string} date
 * @returns {string} with DD-MM-YYYY format
 */
export const formatDateStringToDisplay = (date: string) => {
  if (!date || !dayjs(date).isValid()) return date;
  return dayjs(date).format('DD/MM/YYYY');
};

/**
 * Change the decimal separator in a percentage string from dot to comma.
 * If the received string doesn't end with a percent sign then the original string
 * is returned without modifications.
 * @param {string} percentage
 * @returns {string}
 */
export const changeDecimalSeparatorInPercentage = (percentage: string) => {
  if (!percentage || !percentage.endsWith('%')) return percentage;
  return percentage.replace('.', ',');
};
