import React, { useEffect, useRef } from 'react';
import Tooltip from 'bootstrap/js/dist/tooltip';
import { JsonPropsType } from '../../component-loader';
import { CapitalRaise } from '../../types/funds';
import { LoadingOverlay } from '../overlay';
import { formatDateStringToDisplay, formatToLocaleNumberString } from '../../utils/format';

interface CapitalRaiseItemProps {
  capitalRaise: CapitalRaise
}

function CapitalRaiseItem({ capitalRaise }: CapitalRaiseItemProps) {
  const tooltipRef = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      Tooltip.getOrCreateInstance(tooltipRef.current);
    }
  }, []);

  return (
    <div className="d-flex flex-column gap-1">
      <p className="mb-0" style={{ color: '#808191' }}>{formatDateStringToDisplay(capitalRaise.quote_value_date)}</p>
      <p className="mb-0 text-gray-600">
        Cuotas:
        {' '}
        <span className="fw-bold">
          {formatToLocaleNumberString({ value: capitalRaise.total_shares, round: true })}
        </span>
      </p>
      <p className="mb-0 text-gray-600">
        { capitalRaise.name }
        :
        {' '}
        <div className="d-inline-flex align-items-center">
          <span className="fw-bold">
            {formatToLocaleNumberString({ value: capitalRaise.quote_value, currencyFormat: 'clp' })}
          </span>
          <span
            ref={tooltipRef}
            className="material-icons-two-tone ms-1 fs-base"
            aria-hidden="true"
            title="Valor cuota"
            data-bs-toggle="tooltip"
          >
            info
          </span>
        </div>
      </p>
      <p className="mb-0" style={{ color: '#808191' }}>
        Total Inversión:
        {' '}
        <span className="fw-bold">
          {formatToLocaleNumberString({ value: capitalRaise.total_patrimony, currencyFormat: 'clp', round: true })}
        </span>
      </p>
      <hr className="m-0" />
    </div>
  );
}

export interface DashboardCapitalCallsProps {
  data: CapitalRaise[];
  subtitle: string;
  fundUrl: string;
  loading: boolean;
}

export function DashboardCapitalCalls({ jsonObject }: JsonPropsType<DashboardCapitalCallsProps>) {
  if (!jsonObject) return <div />;

  const {
    data,
    subtitle,
    fundUrl,
    loading,
  } = jsonObject;
  return (
    <div className="card overflow-hidden">
      <div className="card-body position-relative">
        <LoadingOverlay show={loading} />
        <p className="h6 text-gray-800 mb-2">Información general del fondo</p>
        <p className="text-gray-600">{subtitle}</p>
        <div className="d-flex flex-column gap-3">
          {data.map(
            (capitalRaise) => (
              <CapitalRaiseItem capitalRaise={capitalRaise} key={capitalRaise.name} />
            )
          )}
        </div>
        <a href={fundUrl} className="btn btn-outline-primary mt-4 w-100">
          Ver más información
          <span className="icon material-icons-two-tone ms-auto" aria-hidden="true">
            keyboard_arrow_right
          </span>
        </a>
      </div>
    </div>
  );
}
