import React, { ReactNode, useState } from 'react';
import { JsonPropsType } from '../../component-loader';
import { AsyncTable, AsyncTableProps } from './AsyncTable';
import { AsyncTableActionCell } from './AsyncTableActionCell';
import { ActiveFund } from '../../types/funds';
import { changeDecimalSeparatorInPercentage, formatToLocaleNumberString } from '../../utils/format';

export type ActiveFundsTableProps = AsyncTableProps<ActiveFund>;

export function ActiveFundsTable(props: JsonPropsType<ActiveFundsTableProps>) {
  const [activeFunds, setActiveFunds] = useState<ActiveFund[]>([]);
  if (!props.jsonObject) return <div />;

  const { url, columns } = props.jsonObject;
  const onSuccess = (data: ActiveFund[]) => setActiveFunds(data);
  const rows: ReactNode[] = activeFunds.map((activeFund) => (
    <tr key={activeFund.id}>
      <td>{activeFund.name}</td>
      <td>{activeFund.series}</td>
      <td>{formatToLocaleNumberString({ value: activeFund.quote_price, currencyFormat: 'clp' })}</td>
      <td>{formatToLocaleNumberString({ value: activeFund.number_of_quotes, round: true })}</td>
      <td>{formatToLocaleNumberString({ value: activeFund.my_shares, round: true })}</td>
      <td>{formatToLocaleNumberString({ value: activeFund.my_assets, currencyFormat: 'clp', round: true })}</td>
      <td>{changeDecimalSeparatorInPercentage(activeFund.percentage_of_shares)}</td>
      <AsyncTableActionCell jsonObject={{ url: `${activeFund.url}` }} />
    </tr>
  ));

  return (
    <AsyncTable<ActiveFund>
      jsonObject={{
        url,
        onSuccess,
        columns,
        rows,
      }}
    />
  );
}
