import React, { ReactNode, useState } from 'react';
import { JsonPropsType } from '../../component-loader';
import { AsyncTable, AsyncTableProps } from './AsyncTable';
import { changeDecimalSeparatorInPercentage, formatToLocaleNumberString } from '../../utils/format';
import { FundSeries } from '../../types/funds';

export interface FundsSeriesTableProps extends Omit<AsyncTableProps<FundSeries>, 'onSuccess' | 'rows'> {
  hideQuotes?: boolean
}

export function FundSeriesTable(props: JsonPropsType<FundsSeriesTableProps>) {
  const [fundSeries, setFundSeries] = useState<FundSeries[]>([]);
  if (!props.jsonObject) return <div />;

  const { url, columns, hideQuotes = false } = props.jsonObject;
  const onSuccess = (data: FundSeries[]) => setFundSeries(data);
  const rows: ReactNode[] = fundSeries.map((item) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>
        {formatToLocaleNumberString({ value: item.quota_price, currencyFormat: 'clp' })}
      </td>
      {
      !hideQuotes
      && <td>{formatToLocaleNumberString({ value: item.total_shares, round: true })}</td>
      }
      <td>{formatToLocaleNumberString({ value: item.number_of_quotes, round: true })}</td>
      <td>{formatToLocaleNumberString({ value: item.my_assets, currencyFormat: 'clp', round: true })}</td>
      <td>{changeDecimalSeparatorInPercentage(`${item.percentage_of_shares}%`)}</td>
    </tr>
  ));

  return (
    <AsyncTable<FundSeries>
      jsonObject={{
        url,
        onSuccess,
        columns,
        rows,
      }}
    />
  );
}
